import Api from '@/axios'

export default {
  login (payload) {
    return Api().post('auth/login', payload)
  },
  registration (payload) {
    return Api().post('auth/register', payload)
  },
  logout () {
    return Api().post('auth/logout')
  },
  loginSocial (provider) {
    return Api().get(`auth/socials/${provider}/login`)
  },
  forgetPassword (payload) {
    return Api().post('auth/forget', payload)
  },
  resetPassword (payload) {
    return Api().post('auth/reset', payload)
  }
}
